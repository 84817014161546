<template>
  <div id="vital1" class="custer_content">
    <div class="index1"></div>
    <div class="index2">
      <div class="custer_box haerbing">
        <div class="test">
          <div class="address">{{ haerbin.name }}</div>
          <div class="time">{{ haerbin.time }}</div>
          <div class="num_row">
            <div class="num_box" v-for="item in haerbin.numList">
              <div class="num_name">{{ item.name }}</div>
              <div class="num_desc">{{ item.desc }}</div>
            </div>
          </div>
          <div class="desc_box">
            <div class="desc" v-for="item in haerbin.desc">
              {{ item }}
            </div>
          </div>

          <div class="img_list">
            <el-carousel
              type="card"
              height="2.22rem"
              indicator-position="none"
              arrow="never"
            >
              <el-carousel-item v-for="item in imgList">
                <img :src="item" alt="" />
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
      </div>
      <div class="custer_box shijiazhuang">
        <div class="test">
          <div class="address">{{ shijiazhuang.name }}</div>
          <div class="time">{{ shijiazhuang.time }}</div>
          <div class="num_row">
            <div class="num_box" v-for="item in shijiazhuang.numList">
              <div class="num_name">{{ item.name }}</div>
              <div class="num_desc">{{ item.desc }}</div>
            </div>
          </div>
          <div class="desc_box">
            <div class="desc" v-for="item in shijiazhuang.desc">
              {{ item }}
            </div>
          </div>

          <div class="time_desc">
            <div class="custer_row" v-for="row in timeList">
              <div class="time_box" v-for="item in row">
                <div class="time_name">{{ item.name }}</div>
                <div class="time_date">{{ item.date }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "case",
  components: {},
  data() {
    return {
      imgList: [
        "https://imagepub.chinau8.cn/tianhua_jinhua/chinau8-website/youba2.png",
        "https://imagepub.chinau8.cn/tianhua_jinhua/chinau8-website/youba1.png",

        "https://imagepub.chinau8.cn/tianhua_jinhua/chinau8-website/youba3.png",
      ],
      haerbin: {
        name: "哈尔滨",
        time: "2018.12上线",
        numList: [
          { name: "车辆总数(辆)", desc: "7523" },
          { name: "平台完成订单(个)", desc: "53000" },
          { name: "运送客数量(人)", desc: "1600000" },
        ],
        desc: [
          "服务平台启动仪式2018年12月，哈尔滨旅游客运智能服务平台正式启动!",
          "哈尔滨旅游客运智能服务平台2018年12月平台上线以来，平台完成订单超过53000个，运送旅客数达到160万人次，交易额突破8000万。",
        ],
      },
      shijiazhuang: {
        name: "石家庄",
        time: "2018.12上线",
        numList: [
          { name: "车辆总数(辆)", desc: "6055" },
          { name: "平台完成订单(个)", desc: "35424" },
          { name: "运送客数量(人)", desc: "1400000" },
        ],
        desc: [
          "中国游巴网正式落户石家庄!2019年10月12日，浙江游巴行信息科技有限公司石家庄分公司正式启动!",
          "石家庄旅游客运智能服务平台2019年10月平台上线以来，平台完成订单超过35424个，运送旅客数达到140万人次，交易额突破1亿。",
        ],
      },
      timeList: [
        [
          { name: "中国游巴网正式落户石家庄", date: "2019年10月12日" },
          { name: "召开公安交警审核系统培训会", date: "2019年12月13日" },
          { name: "石家庄市包车客运车辆识别码上线", date: "2019年10月12日" },
        ],
        [
          { name: "召开客运企业系统培训会", date: "2019年12月18日" },
          { name: "石家庄包车客运网上平台上线仪式", date: "2020年1月13" },
          { name: "研发复工直通车功能板块", date: "2020年疫情期间" },
        ],
      ],
    };
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.custer_content {
  background-color: #f8fcff;
  padding-bottom: 50px;
}
.index1 {
  height: 410px;
  background: url("https://imagepub.chinau8.cn/tianhua_jinhua/beishankou/custer_bg.png")
    no-repeat center center;
  background-size: 100% 100%;
  text-align: left;
  background-color: white;
}

.test {
  width: 1500px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.index2 {
  margin: 0 auto;
  box-sizing: border-box;
  margin-top: 50px;
  background-color: #f8fcff;
  color: rgba(16, 16, 16, 1);
  font-size: 14px;
  display: flex;
  flex-direction: column;
  .shijiazhuang {
    background: url("~@/assets/custer/shijiazhuang.png") no-repeat center center;
    background-size: 1920px 120%;
  }
  .haerbing {
    background: url("~@/assets/custer/haerbing.png") no-repeat center center;
    background-size: 1920px 100%;
  }
  .custer_box {
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .address {
      height: 63px;
      font-size: 42px;
      font-family: Source Han Sans CN-Bold, Source Han Sans CN;
      font-weight: bold;
      color: #333333;
      line-height: 49px;
      letter-spacing: 16px;
      text-indent: 16px;
    }
    .time {
      height: 36px;
      font-size: 24px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #666666;
      line-height: 28px;
    }
    .num_row {
      display: flex;
      width: 100%;
      margin-top: 75px;
      margin-bottom: 90px;
    }
    .num_box {
      flex: 1;
      border-right: 1px solid rgba($color: #dbeaff, $alpha: 1);
      &:nth-child(3) {
        border-right: none;
      }
      .num_name {
        height: 32px;
        font-size: 24px;
        font-family: Source Han Sans CN-Normal, Source Han Sans CN;
        font-weight: 400;
        color: #666666;
        line-height: 32px;
      }
      .num_desc {
        margin-top: 25px;
        height: 60px;
        font-size: 80px;
        font-family: Source Han Sans CN-Heavy, Source Han Sans CN;
        font-weight: 800;
        color: #066bf1;
        line-height: 60px;
      }
    }
    .desc_box {
      display: flex;
      flex-direction: column;
    }
    .desc {
      // margin-top: 90px;
      font-size: 23px;
      font-family: Source Han Sans CN-Normal, Source Han Sans CN;
      font-weight: 400;
      color: #666666;
      line-height: 36px;
      text-align: left;
      text-indent: 46px;
      text-align: left;
    }
  }
  .time_desc {
    margin-top: 120px;
    width: 100%;
  }
  .custer_row {
    display: flex;
    width: 100%;
    justify-content: space-around;
    margin-bottom: 30px;
    .time_box {
      width: 476px;
      height: 140px;
      background: #ffffff;
      box-shadow: 0px 0px 10px 7px rgba(51, 51, 51, 0.03);
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      &:hover {
        background: url("~@/assets/custer/box_hover.png") no-repeat center
          center;
        background-size: 100% 100%;
        .time_name {
          color: white;
        }
      }
      .time_name {
        font-size: 30px;
        font-family: Source Han Sans CN-Medium, Source Han Sans CN;
        font-weight: 500;
        color: #066bf1;
        line-height: 35px;
      }
      .time_date {
        font-size: 24px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #666666;
        line-height: 28px;
      }
    }
  }

  .img_list {
    width: 1280px;
    margin-top: 120px;
  }
  .el-carousel__item {
    img {
      width: 100%;
      height: 100%;
    }
  }
  ::v-deep .el-carousel__mask {
    display: none;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1300px) {
}
</style>
